import http from "@/utils/http";
import { getApiUrl } from "@/utils/url";

const actions = {
  upload: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/system/upload`, {
      data,
    });
  },
  getAvatar: async () => {
    return await http.get(`${getApiUrl()}/api/system/avatar`);
  },
};

export default {
  ...actions,
};
